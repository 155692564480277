.vertical-scroll-snap {
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  max-height: 80vh;
  margin-top: 7rem;
  padding-top: 3rem;
  scroll-behavior: smooth;
  background: linear-gradient(135deg, rgba(168, 237, 234, 0), rgba(254, 214, 227, 1));

  @media (max-width: 768px) {
    margin-top: 8rem;
    padding-top: 1rem;
    max-height: 70vh;
  }
}

.stacking-slide {
  scroll-snap-align: start;
  height: 70vh;
  width: 80%;
  position: sticky;
  margin-left: 10rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
  border: 0.5px solid black;
  top: 0;
  padding: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      color: #666;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 1rem;
    height: 60vh;
    padding: 2rem;
  }
}
