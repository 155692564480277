@keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animate-gradient {
    animation: gradientShift 10s ease infinite; /* Smooth transition duration */
    background-size: 400% 400%;
    background-image: linear-gradient(
      to right,
      rgba(255, 204, 0, 1), /* Bright yellow */
      rgba(255, 153, 51, 1), /* Bright orange */
     
      rgba(153, 51, 255, 1), /* Bright purple */
      rgba(51, 153, 255, 1), /* Bright blue */
      rgba(0, 204, 204, 1), /* Bright turquoise */
      rgba(0, 255, 128, 1), /* Bright green */
      rgba(255, 204, 0, 1) /* Bright yellow */
    );
  }